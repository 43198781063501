.xemelgo-app {
  display: flex;
  flex-direction: column;
}

.xemelgo-app.unavailable .appbox {
  background: #EEEEEE none;
  box-shadow: 0 .063em .25em -.063em rgba(0, 0, 0, 0.5);
}

.xemelgo-app.unavailable .appname {
  color: #b1b1ae;
}

.xemelgo-app .appname {
  text-align: center;
  margin-top: 5%;
  font-size: .54rem;
}

.xemelgo-app .appbox {
  width: 5.375em;
  height: 4.4375em;
  margin: 0 auto;
  text-align: center;
  border-radius: 1.047em;
  background-image: linear-gradient(145deg, #46d2ff, #166ed5);
  box-shadow: 0 .063em .25em -.063em rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.xemelgo-app.clickable .appbox {
  cursor: pointer;
}

.xemelgo-app .appbox img {
  width: 2.2em;
  height: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .xemelgo-app .appbox {
    width: 10.1589em;
    height: 8.125em;
    border-radius: 2.094em;
    box-shadow: 0 .063em .25em -.063em rgba(0, 0, 0, 0.5);
  }
  .xemelgo-app .appbox img {
    width: 4.1589em;
    height: auto;
  }
  .xemelgo-app .appname {
    font-size: 1.08em;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2600px) {
  .xemelgo-app .appbox {
    width: 13.1589em;
    height: 10.125em;
    border-radius: 2.894em;
    box-shadow: 0 .063em .25em -.063em rgba(0, 0, 0, 0.5);
  }
  .xemelgo-app .appbox img {
    width: 5.5em;
    height: auto;
  }
  .xemelgo-app .appname {
    font-size: 1.2em;
  }
}