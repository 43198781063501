.Ripple-parent {
  overflow: visible;
}

.backlog-body {
  padding: 0;
}

.main-card {
  margin: 0em 1em 0em 1em;
}

#main-card-body {
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0;
}

#main-card-body > .title {
  padding: 1em 1.2em 1em 1.2em;
  color: #343434;
  border-bottom: 0.003em #cdcdcd solid;
}

#main-card-body > .title > h2 {
  font-weight: 900;
}

#main-card-body > .mini-title {
  padding: 0.4em 1em 0.3em 0.8em;
  color: #343434;
  font-weight: 900;
  border-bottom: 0.003em #cdcdcd solid;
}

div#dep-threshold {
  display: flex;
  flex-direction: column;
}

div#dep-threshold > ul.nav.nav-tabs {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  box-shadow: 0px 1px #ededed;
}

div#dep-threshold > ul.nav.nav-tabs > li {
  background-color: #ffffff;
}

div#dep-threshold > ul.nav.nav-tabs > li > a {
  color: #343434;
}

div#dep-threshold > ul.nav.nav-tabs > li.active {
  border-bottom: 0.13em solid #46d2ff;
}

div#dep-threshold > ul.nav.nav-tabs > li.active > a {
  font-weight: bold;
  color: #46d2ff;
}

.wrapper {
  overflow: hidden;
}

.card-group-body {
  padding-bottom: 2em;
}

.card-inner-with-error {
  margin-bottom: 1em;
}

.card-inner-thresholds {
  margin: 2em 0em 2em 0em;
  background-color: #fafafa;
}

.historical-backlog-graph {
  margin: 2em 0em 2em 0em;
  box-shadow: none;
}

.threshold-record {
  display: table;
  width: 100%;
  padding: 0.5em 0.1em 0.5em 0em;
  border-top: 0.01rem;
  border-bottom: 0.01rem;
  margin-bottom: 1.5rem;
  line-height: 2.5em;
}

.threshold-record > .threshold-record-input {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  margin-right: 3em;
}

.threshold-record > .threshold-record-input > input {
  border: 0.05rem solid #b8b8bd;
  width: 7rem;
  height: 2rem;
  text-align: center;
}

#threshold-record-input-warning {
  margin-bottom: 0.5rem;
}

.threshold-record > .threshold-record-icon-label {
  display: table-cell;
  vertical-align: middle;
}

.threshold-record > .threshold-record-icon-label > span.threshold-record-label {
  padding-left: 1rem;
  font-size: x-large;
  font-style: normal;
  color: #343434;
}

.threshold-record > .threshold-record-icon-label > i.threshold-icon {
  font-size: small;
  vertical-align: text-top;
}

.warning-threshold {
  color: #ffc107;
}

.critical-threshold {
  color: #dc3545;
}

.historical-backlog-graph > h4 {
  padding-bottom: 0em;
  padding-top: 0.9em;
}

.threshold-form-buttons {
  display: flex;
  justify-content: space-around;
  padding: 0.3em;
}

.btn {
  margin-left: 0em;
  margin-right: 0em;
}

div#dep-threshold > .tab-content > .tab-pane.active {
  display: inline;
}

.threshold-alerts {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.threshold-alerts > h4 {
  text-align: left;
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.threshold-alerts > p {
  margin-bottom: 2px;
  padding-top: 3px;
}

.threshold-detail-title {
  padding-bottom: 0em;
  text-align: left;
  margin-top: 1em;
  margin-left: 1em;
  color: #343434;
  font-weight: bold;
}

.filter-radio-buttons {
  box-sizing: content-box;
}

@media only screen and (min-width: 768px) {
  .card-inner-with-error {
    margin-right: 2%;
    margin-left: 2em;
  }
  div#dep-threshold > ul.nav.nav-tabs {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
  div#dep-threshold > ul.nav.nav-tabs > li {
    display: inline-block;
    padding-left: 2em;
    padding-right: 2em;
  }
  #main-card-body {
  }
  .threshold-record {
    padding: 0.5em 2em 0.5em 0em;
  }
  .historical-backlog-graph {
    margin-right: 2%;
    margin-bottom: 2%;
    margin-left: 2em;
    margin-right: 2em;
    box-shadow: none;
  }
  .card-inner-thresholds {
    margin-left: 2em;
  }
}
