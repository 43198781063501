html {
  min-height: 100%;
}

body {
  background-color: #FAFAFA;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

main {
  display: block
}

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li>a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  padding-top: 8px;
  padding-bottom: 8px;
}

.example-components-list li:last-child>a {
  border-bottom: 0;
}

.example-components-list li>a .fa {
  color: rgba(0, 0, 0, .35);
  float: right;
}

.cancel-button, .confirm-delete-button, .default-button, .switch-button, .add-user-button, .manual-route-button, .request-submit-button {
  text-transform: none;
  letter-spacing: .04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: .9em;
}

.cancel-button:active, .confirm-delete-button:active, .default-button:active, .switch-button:active, .add-user-button:active, .manual-route-button:active, .request-submit-button:active {
  filter: brightness(75%);
}

.cancel-button:disabled, .confirm-delete-button:disabled, .default-button:disabled, .switch-button:disabled, .add-user-button:disabled, .manual-route-button:disabled, .request-submit-button:disabled {
  cursor: default;
  opacity: .3
}

.cancel-button {
  background-color: #eaeaea;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: #343434;
  margin-right: 4%;
  width: 10em;
}

.confirm-delete-button {
  background-color: #F62227;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #F62227;
  color: white;
  width: 10em;
}

.default-button {
  background-color: #166ED5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ED5;
  color: white;
  display: inline-block;
  width: 10em;
}

.switch-button {
  background-color: #166ED5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #46D2FF;
  color: white;
  height: 20%;
  margin-right: 4%;
  width: 40%;
}

.request-submit-button {
  background-color: #166ED5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ED5;
  color: white;
  display: inline-block;
  width: 13em;
  font-size: 1.2em;
}

.add-user-button {
  background-color: #166ED5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ED5;
  color: white;
  width: 100%;
}

.manual-route-button {
  background-color: #166ED5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ED5;
  color: white;
  padding: 7px;
  margin-bottom: .6em
}

.onboard-submit-button {
  text-transform: none;
  letter-spacing: .04rem;
  margin-left: .5em;
  margin-right: 1em;
}

@media only screen and (min-width: 768px) {
  .cancel-button, .confirm-delete-button, .default-button, .switch-button {
    font-size: 1em;
  }
  .manual-route-btn {
    display: inline-block;
    padding: 7px;
    margin-bottom: 0;
  }
  .switch-button {
    width: 20%;
  }
}