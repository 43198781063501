.row-with-action-component>.icons-block .hidden {
  display: none;
}

.row-with-action-component.pending-delete {
  opacity: .5;
  background-image: linear-gradient(to bottom right, #928e8e, #84847b);
}

.row-with-action-component>.editable-table-data-row-cell>input[type="text"] {
  text-align: left;
}

.input-error {
  border-color: red;
}