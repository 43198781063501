/*
Default to mobile view
*/

.app-containers {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2.5%;
}

.appbox {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.appbox:hover, .appbox:focus, .appbox:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.app-containers .xemelgo-app {
  flex-basis: 33%;
  margin-bottom: 2.5%;
}

/**
Laptop view
 */

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .app-containers .xemelgo-app {
    height: 5.5em;
  }
}

.content {
  background-color: white;
  margin-bottom: .5rem;
  margin-top: 1.4375rem;
  margin-left: 7%;
  margin-right: 7%;
}

.home-img {
  width: 100%;
  height: auto;
}

#title {
  position: relative;
  font-size: 1rem;
  color: #343434;
  display: flex;
  margin-bottom: -3.7%;
  font-weight: bold;
  padding-bottom: 5%;
}

.content-container {
  margin-bottom: 1%;
}

.home-searchbar {
  margin-left: 15%;
  width: 70%;
  position: relative;
  z-index: 1000;
}

.topleft, #topright {
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  .app-containers .xemelgo-app {
    height: 100%;
    margin-bottom: 5%;
  }
  .topleft {
    visibility: visible;
    position: absolute;
    top: 36%;
    left: 4%;
  }
  #topright {
    visibility: visible;
    position: absolute;
    top: 37%;
    right: 4%;
    width: 8.5em;
    height: auto;
  }
  .content {
    margin-bottom: 1rem;
    margin-top: 2.875rem;
    margin-left: 15%;
    margin-right: 15%;
  }
  .home-img {
    width: 100%;
    height: auto;
  }
  #title {
    font-size: 1.83rem;
  }
  .home-searchbar {
    margin-top: -15%;
  }
}