b {
  font-weight: bold;
}

.rowC{
  display:flex; 
  flex-direction:row;
  background-color: white;
  border: 1px solid rgba(0,0,0,.125);
  flex-shrink: 0;
  position: relative;
}




.list-group-item-style {
  border: none;
  width : 95%;
  height: auto;
}

.delete-btn-style {
  background: transparent url('./../img/close_icon.png') no-repeat;
  background-position: center;
  background-size: 15px;
  border: none;
  width : 5%;
}

.homeimg img {
  width: 100%;
}

.img-container {
  position: relative;
}

.topleft {
  position: absolute;
  top: 90px;
  left: 35px;
  font-size: 25pt;
  color: white;
  font-weight: 600;
}

#search-form {
  top: 1.3px;
  margin-right: 1%;
  font-size: 0.9rem;
  border-radius: 0.25rem;
}

#searchbtn {
  margin-left: 8px;
  margin-top: 3px;
}

.search-results-div {
  top: 50px;
  position: absolute;
  width: initial;
  z-index: 1000;
  background-color: white;
}

/*
#search-form {
	top: 1.3px;
	margin-right: 1%;
	font-size: .9rem;
	border-radius: .25rem;
}*/

.search-results-overlay {
  max-height: 15em;
  overflow: auto;
  width: auto;
  box-shadow: 0px 5px 12px 2px rgba(0, 0, 0, 0.3);
}

.search-results-fixed
{
  max-height: 14em;
  overflow: auto;
  width: inherit;
  box-shadow: 0px 5px 12px 2px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 768px) {
  #search-form {
    font-size: 1rem;
    height: 2.4rem;
  }
}
textarea {
  overflow: hidden;
}
