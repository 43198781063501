.aggrid-grid {
  width: 100%;
  height: 90%;
  background-color: white;
  color: black;
  margin-right: 5%;
  overflow-y: auto;
}

.location-table {
  height: 40vh;
  overflow: auto;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 3em;
}

.ag-header-cell-text {
  width: 100%;
  font-size: 1.5em;
  font-style: normal;
}

.ag-header-cell-text {
  width: 100%;
  font-size: 1.5em;
  font-style: normal;
}

.ag-group-value {
  font-style: normal;
  font-size: 1em;
}

.ag-group-child-count {
  font-style: normal;
  font-weight: bold;
  font-size: 1.2em;
}

.dropdown-action {
  margin: 0em 1em 0em 2em;
  background-color: rgba(255, 255, 255, 0);
}

.location-table-title > h2 {
  margin-left: 0.5em;
}

.data-table-body {
  overflow: hidden;
  height: 100%;
}

.data-table-body-long {
  padding: 0em, 0em, 0em, 0em;
  max-height: 80vh;
  overflow: auto;
  height: 100%;
}

.page-data-table {
  padding: 1.5em 2em;
  height: 60vh;
}

.page-data-table-short {
  padding: 0em 0em 0em 0em;
  min-width: 50vw;
  max-width: 60vw;
  height: 35vh;
}

.title-header {
  font-weight: bold;
  display: inline-block;
}

.title-header > h3 {
  display: inline;
}

.title-header > p {
  display: inline;
  margin-left: 1em;
  font-weight: 500;
}

h4.table-name {
  font-weight: bold;
}

.actions-bar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.actions {
  margin-left: auto;
  margin-right: 1%;
}

.actions > button {
  width: auto;
  font-size: 0.9em;
  margin-left: 0;
  margin-right: 0.2em;
  margin-top: 0.1em;
}

input#ag-grid-search::placeholder {
  font-size: 0.95em;
}

@media only screen and (min-width: 768px) {
  .actions > button {
    margin-top: 1em;
    margin-left: 1em;
  }
}
