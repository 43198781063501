.content_holder {
  height: auto;
  background-color: white;
  vertical-align: top;
  margin-top: 4.5%;
}

.override_padding {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.lds-roller {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 20% auto auto auto;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: grey;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overview {
  display: inline-block;
  width: 50%;
  height: auto;
  margin-left: 10%;
  padding: 2% 5% 0 0;
  align-content: center;
}

.line {
  position: static;
  background-color: #eee;
  border: 0 none;
  color: #eee;
  height: 2px;
  margin: 0 auto;
  width: 100%;
  top: 46%;
  right: 50;
  left: 0;
  margin-bottom: 1%;
}

.grid_view {
  height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;
  /* display: inline-block; */
}

.order_backlog_chart {
  width: 400px;
  height: auto;
  display: inline-block;
  margin-left: 8%;
  flex-direction: column;
  vertical-align: middle;
  padding-bottom: 2%;
}

@media only screen and (max-width: 1380px) {
  .content_holder {
    margin-top: 8%;
  }

  .overview {
    position: block;
    width: 100%;
    margin: 0;
  }

  .order_backlog_chart {
    margin: 0 auto;
    margin-top: 5%;
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .content_holder {
    margin-top: 15%;
  }
}
