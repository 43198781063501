/* Work around for fixing inline styling from third party */

.tab-with-control-component .tab-control>div[style]:nth-child(1) {
  display: flex !important;
}

/* Work around for fixing inline styling from third party */

.tab-with-control-component .tab-control>div[style]:nth-child(1)>.react-switch-bg>div>svg {
  display: flex !important;
}

.tab-name {
  padding-left: 1em;
  font-size: 1em;
  text-align: left;
}