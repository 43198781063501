.editable-table-component {
  text-align: left;
}

.editable-table-component > .editable-table {
  width: 100%;
}

.editable-table-component
  > .editable-table
  > .editable-table-header
  > .editable-table-header-row
  > .editable-table-header-cell {
  border-bottom: 0.03em solid #979799;
  font-size: large;
  font-weight: bold;
  padding-right: 2em;
  color: #343434;
}

.editable-table-component
  > .editable-table
  > .editable-table-header
  > .editable-table-header-row
  > .icons-header {
  width: 7em;
  border-bottom: 0.03em solid #979799;
}

.editable-table-component > .editable-table > .editable-table-data > .editable-table-data-row {
  margin-top: 1em;
  margin-bottom: 1em;
}

.editable-table-component
  > .editable-table
  > .editable-table-data
  > .editable-table-data-row:hover {
  background-color: #eaeaea;
}

.editable-table-component
  > .editable-table
  > .editable-table-data
  > .editable-table-data-row
  .icons-block {
  display: none;
}

.editable-table-component
  > .editable-table
  > .editable-table-data
  > .editable-table-data-row:hover
  > .icons-block {
  display: block;
}

.editable-table-component
  > .editable-table
  > .editable-table-data
  > .editable-table-data-row
  > .editable-table-data-row-cell
  > input:disabled {
  border: none;
  background: none;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  width: 500px;
}

.editable-table-component > .add-new {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  margin-right: 5em;
  color: #166ed5;
}

.editable-table-component > .add-new > .add-new-button {
  border: none;
  cursor: pointer;
  color: #0b51c5;
}

.editable-table-component > .table-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  margin-right: 5em;
}

.editable-table-component > .table-buttons > button {
  margin-left: 2em;
}

.editable-table-component > .table-buttons.hidden {
  display: none;
}
