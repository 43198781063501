/* .welcome-user {

} */

#searchbtn {
  background-color: #166ED5 !important;
  box-shadow: 0 3px 11px -6px #166ed5;
}

#custom-nav {
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
  color: #343434;
  font-size: 1.1rem;
  /*confirm size*/
}

.custom-active {
  color: #166ED5;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: 'Nunito', sans-serif;
}

.custom-active:hover {
  text-decoration: underline #166ED5;
}

.profile {
  width: auto;
}

.profile:hover {
  text-decoration: underline black;
}

#navbar-new {
  justify-content: space-between !important;
}

#navbar-new>li {
  justify-content: space-between !important;
  text-align: center;
}

.logout {
  color: #F62227;
  margin-bottom: 0px;
}

.searchbar-space>.search-results {
  width: 100%;
  top: 69%;
}

.notification-icon {
  height: 25px;
  width: 23px;
}

.notification-icon {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.notification-icon:hover, .notification-icon:focus, .notification-icon:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.notification-badge {
  /* Use  for notification badge with number
   padding: .285em .2em .2em .2em;
   border-radius: .25rem;
   display: inline-block;
   font-size: 75%;
   font-weight: 700;
   line-height: 0.8;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline; */
  /* Use for only red dot notification badge without number */
  padding: .3em .3em .3em .3em;
  border-radius: .5rem;
  vertical-align: top;
}

@media only screen and (min-width: 768px) {
  .custom-active {
    font-size: 1.7rem;
  }
  .custom-nav {
    font-size: 1.3rem
  }
  .searchbar-space {
    width: 60%;
    margin: 0 auto;
  }
  .searchbar-space>.search-results {
    width: 55%;
    top: 77%;
  }
  .navigation {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .notification-icon {
    height: 1.5em;
    width: 1.3em;
  }
  .seeAllNotifications {
    display: block;
    text-align: center;
    background-color: #F8F8F8;
    margin-bottom: 0px;
    padding: .35em .2em .3em .2em;
  }
  a.seeAllNotifications:link {
    color: #3366ff;
  }
  a.seeAllNotifications:visited {
    color: #3366ff;
  }
  .dropdown {
    margin-right: 0.5em;
  }
  .dropdownmenu {
    padding: 0;
  }
  .nav-item {
    padding-top: .5%;
    float: left;
  }
}