.grid-view-container {
  display: inline-block;
  width: 99%;
  height: 400px;
  overflow-x: hidden;
  padding-left: 1%;
  /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  /* So the width will be 100% + 17px */
}

/* Turn on custom 8px wide scrollbar */

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

/* hover effect for both scrollbar area, and scrollbar 'thumb' */

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

.grid-search-bar {
  font-size: 1.1rem;
  border-radius: 0.25rem;
  width: 94%;
  height: 100%;
}

.filter-group {
  align-items: center;
  height: 50px;
  margin-left: 2%;
  display: flex;
  flex-direction: row;
}

.filter-icon {
  margin-left: 2%;
}

.filter-icon:hover {
  cursor: pointer;
}
