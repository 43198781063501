.historical-graph-title {
  padding-top: 0;
  margin-bottom: .8em;
  display: inline-block;
}

.export-icon {
  display: inline-block;
  margin-left: 5%;
  height: 17px;
  width: 17px;
}

.historical-graph-toolbar {
  text-align: center;
  display: flex;
}

.historical-graph-toolbar .tabs {
  flex: 1;
}

.time-title {
  font-size: .85rem
}

.dropdown .dropdown-menu.show {
  max-height: 50vh;
  overflow: auto;
}

.dropdown .dropdown-item:active {
  background-color: #166ed5 !important;
}

.select {
  color: #166ed5;
  margin: .7em;
  margin-bottom: 1.3em;
  display: inline-block;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .time-title {
    font-size: 1rem
  }
  .export-icon {
    display: inline-block;
    margin-left: 15px;
  }
}