table {
  width: 100%;
  margin: auto auto 5% auto;
}
@-moz-document url-prefix() {
  table {
    margin: 0 auto;
  }
}
.table-title {
  font-family: Tahoma;
  text-align: left;
  width: 100%;
  padding-bottom: 1%;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
}
.status-color {
  color: red;
}
.body-text {
  font-size: 30px;
  font-weight: bold;
  width: 20%;
  height: auto;
  text-align: center;
}
.header-text {
  padding: 2% 5% 0 5%;
  width: 20%;
  height: auto;
  text-align: center;
}
