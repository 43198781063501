.loader {
  margin: 0 auto;
  margin-top: 5%;
}

.loader>.css-17505b1 {
  margin: 0 auto;
}

ul.loading-component {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 1.5);
  justify-content: center;
  align-items: center;
}

ul.loading-component>li {
  list-style: none;
  color: #484848;
  font-family: Arial;
  font-size: large;
  letter-spacing: 15px;
  animation: animate 1.4s linear infinite;
}

@keyframes animate {
  0% {
    color: #484848;
    text-shadow: none;
  }
  90% {
    color: #484848;
    text-shadow: none;
  }
  0% {
    color: #fff000;
    font-size: medium;
    text-shadow: 0 0 7px #fff000, 0 0 50px #ff6c00;
  }
}

ul.loading-component>li:nth-child(1) {
  animation-delay: .2s;
}

ul.loading-component>li:nth-child(2) {
  animation-delay: .4s;
}

ul.loading-component>li:nth-child(3) {
  animation-delay: .6s;
}

ul.loading-component>li:nth-child(4) {
  animation-delay: .8s;
}

ul.loading-component>li:nth-child(5) {
  animation-delay: 1s;
}

ul.loading-component>li:nth-child(6) {
  animation-delay: 1.2s;
}

ul.loading-component>li:nth-child(7) {
  animation-delay: 1.4s;
}