.add-user-alerts {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.add-user-alerts>h4 {
  text-align: left;
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.add-user-alerts>p {
  margin-bottom: 2px;
  padding-top: 3px;
}