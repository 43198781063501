.request-header {
  border-bottom: 1px solid gray;
}

.input-error-form {
  border: 1px solid red;
  border-radius: 0.3rem;
}

.back-button-text {
  width: fit-content;
  cursor: pointer;
  color: #1e71d2;
}

.back-button-icon {
  width: 10px;
  vertical-align: baseline;
}

.search-form {
  margin: 0em 5em 3em 5em;
}

.search-form-label {
  font-weight: bold;
  font-size: 1.5em;
}

.new-request-card {
  min-height: 50em;
  margin: 0px 0px 20px 20px;
}

.submit-form {
  margin: 1em 0em 2em 0em;
  display: flex;
}

.horizontal-center-align {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.form-input-label {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 0.2em;
}

.input-div {
  height: 100px;
  width: 50%;
  margin-bottom: 2em;
  background: transparent;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.input-error-message {
  color: red;
}

.modal-dialog {
  width: 90%;
  max-width: 2000px;
}
.modal-header {
  padding-top: 1rem; 
  padding-bottom: 0rem;
}

.modal-body {
  padding-top: 1rem;
  padding-bottom: 0rem;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.modal-title, .modal-body {
  margin: 0px 30px 0px 30px;
}

.cancel-button-modal {
  display: inline-block;
  width: 13em;
  height: 2em;
  font-size: 1.2em;
  text-transform: none;
  letter-spacing: .04rem;
  border: none;
  border-radius: 0.2em;
  margin-right: 15px;
}

.submit-button-modal {
  background-color: #166ED5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ED5;
  color: white;
  display: inline-block;
  width: 13em;
  height: 2em;
  font-size: 1.2em;
  text-transform: none;
  letter-spacing: .04rem;
  border: none;
  border-radius: 0.2em;
  margin-left: 15px;
}

.close-button-modal{
  cursor: pointer;
  position: relative;
  right: 2%;
  padding: 12px 16px;
  border: none;
  background-color: white;
}

.close-button-modal:hover {background: #bbb;}

.modal-footer {
  display: block !important;
  text-align: center;
  margin-bottom: 25 px;
}

.duplicate-request-modal-buttons {
  margin-top: 25px;
}

.duplicate-request-card {
  border-radius: 25px;
  border: 2px solid #166ED5;
  font-size: 0.9em;
  margin: 1.2em;
  padding: 20px;
}

.new-request-form {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  margin: 1.2em;
  border: 2px solid #166ED5;
  padding: 20px;
  width: 100%;
}

.new-request-form-title {
  margin: 1em 0em 1em 0em;
  font-size: 0.9em;
}

.new-request-form-group0 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 400px;
  column-count: 2;
  font-size: 0.9em;
}

.new-request-form-group1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 350px;
  column-count: 2;
  font-size: 0.9em;
}

.no-duplicate-request-found {
  margin: 1.2em;
  text-align: center;
}