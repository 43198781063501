.summary-tile {
  cursor: pointer;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  width: 10%;
  max-width: 19em;
  min-width: 150px;
  height: 98%;
  min-height: 90px;
  margin-left: 1.3em;
  margin-bottom: 1em;
  float: left;
}

.filter-disabled {
  opacity: 0.3;
}

.summary-tile-border-grey {
  border-top: 0.3em solid rgb(153, 153, 153) !important;
}

.summary-tile-border-green {
  border-top: 0.3em solid rgb(49, 198, 58) !important;
}

.summary-tile-border-red {
  border-top: 0.3em solid rgb(246, 34, 39) !important;
}

.summary-tile-border-yellow {
  border-top: 0.3em solid rgb(255, 179, 33) !important;
}

.summary-tile-border-blue {
  border-top: 0.3em solid rgb(49, 131, 238) !important;
}

.summary-tile-border-purple {
  border-top: 0.3em solid rgb(138, 79, 251) !important;
}

.summary-tile-body {
  padding: 0em;
}

.dept-indicator-image {
  width: 10px;
  height: 10px;
  margin-bottom: 0.2em;
  margin-right: 0.2em;
}

.summary-tile-title {
  text-align: center;
  font-weight: 500;
  font-size: 80%;
  padding: 0.3em;
}

.summary-tile-background-grey {
  background-color: rgb(234, 234, 234);
}

.summary-tile-background-green {
  background-color: rgb(210, 247, 197);
}

.summary-tile-background-red {
  background-color: rgb(252, 221, 229);
}

.summary-tile-background-yellow {
  background-color: rgb(255, 234, 184);
}

.summary-tile-background-blue {
  background-color: rgb(210, 239, 253);
}

.summary-tile-background-purple {
  background-color: rgb(236, 226, 252);
}

.summary-tile-content {
  background-color: 'white';
  height: 1em;
}

.summary-tile-left-column {
  padding: 0em;
  margin-top: 1em;
  width: 100%;
  text-align: center;
  float: left;
}

.left-column-number {
  font-size: 2em;
  font-weight: 500;
}

.right-column-content {
  vertical-align: middle;
}

.status-count {
  font-weight: 600;
  font-size: 14px;
  width: 29%;
  margin-right: 3%;
  float: left;
  text-align: right;
}

.status-name {
  font-weight: 600;
  font-size: 14px;
  float: left;
  text-align: left;
  width: 68%;
}

.missing-text {
  color: rgb(246, 34, 39);
}

.rework-text {
  color: rgb(123, 1, 1);
}

.expedited-text {
  color: rgb(228, 90, 0);
}

.time-limit-text {
  color: rgb(0, 45, 228);
}