.profile-fields .input-text, .profile-fields .md-form label {
  color: #343434;
  background-color: rgba(0, 0, 0, 0);
  text-align: left;
}

.admin-field{
  color: #343434;
  text-align: left;
  font-size: 13.5px;
  padding-right: 7px;
  box-sizing: border-box;
  outline: none;
  font-weight: 100;
  font-family: 'Nunito', sans-serif;
}

.checkbox{
  vertical-align: middle
}

.md-form {
  margin-bottom: 0;
  padding-top: 10px;
  position: "relative";
}

.profile-fields .phone-input {
  height: 50px;
}

.profile-fields .phone-input ::placeholder {
  color: #6C757D;
}

.optional-text {
  font-weight: 400;
  color: gray;
  font-style: italic;
}

.optional-text-error {
  font-weight: 400;
  color: #D30F00;
}

.error-message-group {
  display: block;
}

.error-message-text {
  text-align: center;
  color: #D30F00;
  font-weight: bold;
}

.react-phone-number-input__icon {
  width: 2.15em !important;
  height: auto !important;
}