table {
  margin: auto auto 5% auto;
}
@-moz-document url-prefix() {
  table {
    margin: 0 auto;
  }
}
.table_title {
  font-family: Tahoma;
  text-align: left;
  width: 100%;
  padding-bottom: 1%;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
}

.green_color_text {
  color: #62d32e;
}

.yellow_color_text {
  color: #f6b045;
}

.red_color_text {
  color: #ff3d46;
}

.body_text {
  font-size: 30px;
  font-weight: bold;
  width: 20%;
  height: auto;
  text-align: center;
}
.header_text {
  padding: 2% 5% 0 5%;
  width: 20%;
  height: auto;
  text-align: center;
}
