.legend-item {
  position: relative;
  display: block;
  text-align: left;
  padding-left: 4%;
}

.recharts-legend-wrapper {
  font-size: medium;
}

#recharts_measurement_span {
  padding: 0;
}

.recharts-default-legend {
  padding-left: 2.5em !important;
  width: 100%;
}

.recharts_measurement_span {
  padding: 0;
}

#filter {
  float: right;
}

.filterDiv {
  padding-top: 3%;
  padding-bottom: 2%;
  text-align: center;
}

.historical-graph-title {
  text-align: left;
  color: #343434;
  margin-bottom: 2em;
}

.chart_title {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 800;
  font-size: 1.2em;
}

@media only screen and (min-width: 768px) {
  .time-filter {
    padding: 0.4rem 1.9rem !important;
    font-size: 0.8rem !important;
  }
  .filterDiv {
    padding-top: 3%;
    padding-bottom: 2%;
    padding-left: 2.1%;
    text-align: left;
  }
  .recharts-default-tooltip {
    background-color: rgba(255, 255, 255, 0.8) !important;
    border: 1px solid #f5f5f5 !important;
  }
  .recharts-tooltip-wrapper {
    pointer-events: none;
    visibility: visible;
    position: absolute !important;
  }
  /* .customized-legend {
    text-align: center;
    padding-left:4%;
  }

  .legend-item {
    position: relative;
    display: block;
    text-align: left;
  } */
}
