.bar-container {
  width: 100%;
  display: relative;
  position: absolute;
  font-family: 'Roboto', 'Lucida Grande', 'Lucida', sans-serif;
  margin-top: 70px;
  background: transparent;
  padding: 1%;
}

.box {
  height: 25px;
  position: relative;
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
}

.box .fill {
  background: rgba(255, 0, 0, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.current-stock-mark {
  margin-top: -5px;
  height: 10px;
  position: relative;
  top: 5px;
  z-index: 3;
  background-image: url('../../../../img/down_caret_v2.png');
  background-repeat: no-repeat;
}

.tick-mark {
  font-size: 8px;
  border-left: 1px solid black;
  height: 8px;
  position: absolute;
  left: 5%;
  z-index: 3;
}

.show-overstock {
  left: 95%;
}

.bolder {
  font-weight: 450;
}

p.marker-zero {
  margin: 8px 0 0 -4px;
}

p.marker-number {
  margin: 8px 0 -5px -28px;
  text-align: center
}

p.marker-text {
  margin: 0 0 0 -30px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  p.marker-zero, p.marker-number, p.marker-text {
    font-size: 12px;
  }
}

p[data-value] {
  font-size: 16px;
  font-weight: bolder;
  position: relative;
  text-align: center;
  white-space: pre;
  margin: auto auto 7% 5%;
  padding-left: 5%;
  padding-right: 5%;
}

p[data-value]:after {
  content: attr(data-value) ' units';
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 850px) {
  .bar-container {
    display: none;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1000px) {
  .bar-container {
    width: 80%;
    margin-left: 8%;
  }
  p[data-value] {
    margin-bottom: 15%;
    margin-left: 12%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1380px) {
  .bar-container {
    width: 85%;
    margin-left: 5%;
  }
  p[data-value] {
    margin-bottom: 11%;
    margin-left: 9%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 1380px) and (max-width: 1900px) {
  .bar-container {
    width: 90%;
    margin-left: 3%;
  }
  p[data-value] {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 1900px) {
  .bar-container {
    width: 90%;
    margin-left: 3%;
  }
  p[data-value] {
    margin-bottom: 5%;
    margin-left: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}